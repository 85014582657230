*{
  scroll-behavior: smooth;
  /* font-family: "Poppins",sans-serif; */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

/* General styles for the home page */
.home-page {
  text-align: center;
  position: relative;
}

.banner {
  position: relative;
  width: 100%;
  max-height: 600px; /* Use max-height for responsiveness */
  overflow: hidden;
  opacity: 1;
  /* animation: fadeIn 1s ease-in-out fos */
}

.banner img {
  width: 100%;
  height: auto; /* Let the height adapt to the width for responsive images */
  object-fit: cover;
}

.banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 123, 255, 0.5);
}

.banner-content {
  position: absolute;
  top: 50%;
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%);
  color: white;
  text-align: center; /* Center text */
  padding: 20px;
}

.contact-button {
  display: inline-block;
  padding: 10px 20px; /* Adjust padding for responsiveness */
  background-color: #fa4b1f;
  color: white;
  text-decoration: none;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 20px 0; /* Add margin for separation */
}

.banner h1 {
  font-size: 50px; /* Adjust font size for smaller screens */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  color: white;
}

.banner h2 {
  font-size: 30px; /* Adjust font size for smaller screens */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  color: white;
}

/* .banner h1, .banner h2 {
  opacity: 1;
  transform: translateY(-60px);
  animation: fadeInDown 1.5s ease forwards;
} */

/* @keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
} */

/* @keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */

.contact-button:hover {
  background-color: #fa4b1f;
}

.about-sections {
  padding: 60px 0;
  min-height: 100vh;
}

/* Add Media Queries for Responsiveness */
@media (max-width: 768px) {
  .banner h1 {
    font-size: 24px; /* Adjust font size for smaller screens */
  }
  
  .banner h2 {
    font-size: 20px; /* Adjust font size for smaller screens */
  }

  .contact-button {
    padding: 8px 16px; /* Adjust padding for smaller screens */
  }
}

@media (max-width: 480px) {
  .banner h1 {
    font-size: 20px; /* Adjust font size for even smaller screens */
  }

  .banner h2 {
    font-size: 18px; /* Adjust font size for even smaller screens */
  }

  .contact-button {
    padding: 3px 10px; /* Adjust padding for even smaller screens */
  }
}


@media (max-width: 480px) {
  .banner h2 {
    font-size: 18px; /* Adjust font size for even smaller screens */
  }

}

 /*  We offer text area animation*/

 

a, a:hover, a:focus, a:active {
  text-decoration: none;
  outline: none;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}.bg-gray {
  background-color: #f9f9f9;
}

.site-heading h2 {
  font-size: 40px;
display: block;
font-weight: 700;
margin-bottom: 10px;
text-transform: uppercase;
}

.site-heading h2 span {
color: #e88822;
}

.site-heading h4 {
display: inline-block;
padding-bottom: 20px;
position: relative;
text-transform: capitalize;
z-index: 1;
font-size: 25px;
}

.site-heading h4::before {
background: #e88822 none repeat scroll 0 0;
bottom: 0;
content: "";
height: 2px;
left: 50%;
margin-left: -25px;
position: absolute;
width: 50px;
}

.site-heading {
margin-bottom: 60px;
overflow: hidden;
margin-top: -5px;
}

.carousel-shadow .owl-stage-outer {
margin: -15px -15px 0;
padding: 15px;
}

.we-offer-areaa .our-offer-carousel .owl-dots .owl-dot span {
background: #ffffff none repeat scroll 0 0;
border: 2px solid;
height: 15px;
margin: 0 5px;
width: 15px;
}

.we-offer-areaa .our-offer-carousel .owl-dots .owl-dot.active span {
background: #e88822 none repeat scroll 0 0;
border-color: #e88822;
}

.we-offer-areaa .item {
background: #ffffff none repeat scroll 0 0;
border-left: 2px solid #e88822;
-moz-box-shadow: 0 0 10px #cccccc;
-webkit-box-shadow: 0 0 10px #cccccc;
-o-box-shadow: 0 0 10px #cccccc;
box-shadow: 0 0 10px #cccccc;
overflow: hidden;
padding: 30px;
position: relative;
z-index: 1;
}

.we-offer-areaa.text-center .item {
background: #ffffff none repeat scroll 0 0;
border: medium none;
padding: 40px 40px 64px;
border-radius: 10px;
}

#serivceHeadings{
  font-size: 14.2px;
}

.we-offer-areaa.text-center .item i {
background: #e88822 none repeat scroll 0 0;
-webkit-border-radius: 50%;
-moz-border-radius: 50%;
border-radius: 50%;
color: #ffffff;
font-size: 40px;
height: 80px;
line-height: 80px;
position: relative;
text-align: center;
width: 80px;
z-index: 1;
transition: all 0.35s ease-in-out;
-webkit-transition: all 0.35s ease-in-out;
-moz-transition: all 0.35s ease-in-out;
-ms-transition: all 0.35s ease-in-out;
-o-transition: all 0.35s ease-in-out;
margin-bottom: 25px;
}

.we-offer-areaa.text-center .item i::after {
border: 2px solid #e88822;
-webkit-border-radius: 50%;
-moz-border-radius: 50%;
border-radius: 50%;
content: "";
height: 90px;
left: -5px;
position: absolute;
top: -5px;
width: 90px;
z-index: -1;
transition: all 0.35s ease-in-out;
-webkit-transition: all 0.35s ease-in-out;
-moz-transition: all 0.35s ease-in-out;
-ms-transition: all 0.35s ease-in-out;
-o-transition: all 0.35s ease-in-out;
}

.we-offer-areaa.item-border-less .item {
border: medium none;
}

.we-offer-areaa .our-offer-items.less-carousel .equal-height {
margin-bottom: 30px;
}

.we-offer-areaa.item-border-less .item .number {
font-family: "Poppins",sans-serif;
font-size: 50px;
font-weight: 900;
opacity: 0.1;
position: absolute;
right: 30px;
top: 30px;
}

.our-offer-carousel.center-active .owl-item:nth-child(2n) .item,
.we-offer-areaa.center-active .single-item:nth-child(2n) .item {
background: #e88822 none repeat scroll 0 0;
}

.our-offer-carousel.center-active .owl-item:nth-child(2n) .item i,
.our-offer-carousel.center-active .owl-item:nth-child(2n) .item h4,
.our-offer-carousel.center-active .owl-item:nth-child(2n) .item p,
.we-offer-areaa.center-active .single-item:nth-child(2n) .item i,
.we-offer-areaa.center-active .single-item:nth-child(2n) .item h4,
.we-offer-areaa.center-active .single-item:nth-child(2n) .item p {
color: #ffffff;
}

.we-offer-areaa .item i {
color: #e88822;
display: inline-block;
font-size: 60px;
margin-bottom: 20px;
}

.we-offer-areaa .item h4 {
font-weight: 600;
text-transform: capitalize;
}

.we-offer-areaa .item p {
margin: 0;
}

.we-offer-areaa .item i,
.we-offer-areaa .item h4,
.we-offer-areaa .item p {
transition: all 0.35s ease-in-out;
-webkit-transition: all 0.35s ease-in-out;
-moz-transition: all 0.35s ease-in-out;
-ms-transition: all 0.35s ease-in-out;
-o-transition: all 0.35s ease-in-out;
}

.we-offer-areaa .item::after {
background: #e88822 none repeat scroll 0 0;
content: "";
height: 100%;
left: -100%;
position: absolute;
top: 0;
transition: all 0.35s ease-in-out;
-webkit-transition: all 0.35s ease-in-out;
-moz-transition: all 0.35s ease-in-out;
-ms-transition: all 0.35s ease-in-out;
-o-transition: all 0.35s ease-in-out;
width: 100%;
z-index: -1;
}

.we-offer-areaa .item:hover::after {
left: 0;
}

.we-offer-areaa .item:hover i,
.we-offer-area .item:hover h4,
.we-offer-areaa .item:hover p {
color: #ffffff !important;
}

.we-offer-areaa.text-center .item:hover i::after {
  border-color: #ffffff !important;
}

.we-offer-areaa.text-center .item:hover i {
  background-color: #ffffff !important;
  color: #e88822 !important;
}

/* .psychology{
  width: 416px;
  height: 387px;
} */

.we-offer-areaa.text-left .item i {
  background: #e88822 none repeat scroll 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #ffffff;
  display: inline-block;
  font-size: 60px;
  height: 100px;
  line-height: 100px;
  margin-bottom: 30px;
  position: relative;
  width: 100px;
  z-index: 1;
  text-align: center;
}

.we-offer-areaa.text-left .item i::after {
  border: 2px solid #e88822;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  content: "";
  height: 120px;
  left: -10px;
  position: absolute;
  top: -10px;
  width: 120px;
}

/* Add this CSS to your existing styles */
.service-icon {
  text-align: center;
  height: 200px; /* Adjust the height as needed to fit your images */
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-icon img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

* {
  box-sizing: border-box;
}

body,
html {
  overflow-x: hidden;
}

body {
  margin: 0;
  width: 100%;
  /* font-family: "Oswald", sans-serif; */
  font-size: 12pt;
  font-weight: 400;
}
h1,
h2,
h3,
h4,
h5,
h6 {}
a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
}

.abouttitle {
  font-size: 28px; /* Adjust font size as needed */
  color: #333; /* Change text color */
  font-weight: bold; /* Make the text bold */
  margin-bottom: 20px; /* Add some bottom margin */
  width: 500px;
  /* Add any additional styling you desire */
}

a,
a:active,
a:focus {
    color: white;
    text-decoration: none;
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
img {
  height: auto;
  max-width: 100%;
}
section {
    padding: 60px 0;
   /* min-height: 100vh;*/
}

.sec-titlea{
  position:relative;
  z-index: 1;
  margin-bottom:60px 
}

.sec-titlea .title{
position: relative;
display: block;
font-size: 18px;
line-height: 24px;
color: #00aeef;
font-weight: 500;
margin-bottom: 15px;
}

.sec-titlea h2{
position: relative;
display: block;
font-size:40px;
line-height: 1.28em;
color: #222222;
font-weight: 600;
padding-bottom:18px;
}

.sec-titlea h2:before{
position:absolute;
content:'';
left:0px;
bottom:0px;
width:50px;
height:3px;
background-color:#d1d2d6;
}

.sec-titlea .text{
position: relative;
font-size: 16px;
line-height: 26px;
color: #848484;
font-weight: 400;
margin-top: 35px;
}

.sec-titlea.light h2{
color: #ffffff;
}

.sec-titlea.text-center h2:before{
left:50%;
margin-left: -25px;
}

.list-style-one{
position:relative;
}

.list-style-one li{
position:relative;
font-size:16px;
line-height:26px;
color: #222222;
font-weight:400;
padding-left:35px;
margin-bottom: 12px;
}

.list-style-one li:before {
content: "\f058";
position: absolute;
left: 0;
top: 0px;
display: block;
font-size: 18px;
padding: 0px;
color: #ff2222;
font-weight: 600;
-moz-font-smoothing: grayscale;
-webkit-font-smoothing: antialiased;
font-style: normal;
font-variant: normal;
text-rendering: auto;
line-height: 1.6;
/* font-family: "Font Awesome 5 Free"; */
}

.list-style-one li a:hover{
color: #44bce2;
}

.btn-style-one{
  position: relative;
  display: inline-block;
  font-size: 17px;
  line-height: 30px;
  color: #ffffff;
  padding: 10px 30px;
  font-weight: 600;
  overflow: hidden;
  letter-spacing: 0.02em;
  background-color: #00aeef;
  border-radius: 20px;
}

.btn-style-one:hover{
background-color: #0794c9;
color: #ffffff;
}



.about-sections {
  position: relative;
  padding: 120px 0 70px;
}

.about-sections .sec-titlea {
  margin-bottom: 45px;
}

.about-sections .content-column {
  position: relative;
  margin-bottom: 50px;
}

.about-sections .content-column .inner-column {
  position: relative;
  padding-left: 30px;
}

.about-sections .text {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 26px;
  color: #848484;
  font-weight: 400;
  width: 500px;
}

/* Media query for screens smaller than 768px (typical mobile devices) */
@media screen and (max-width: 768px) {
  .about-sections .text {
    font-size: 14px; /* Adjust font size for smaller screens */
    line-height: 22px; /* Adjust line height for smaller screens */
  }
}

.about-sections .btn-box {
  position: relative;
}

.about-sections .btn-box a {
  padding: 15px 50px;
}

.about-sections .image-column {
  position: relative;
}

.about-sections .image-column .text-layer {
  position: absolute;
  right: -110px;
  top: 50%;
  font-size: 325px;
  line-height: 1em;
  color: #ffffff;
  margin-top: -175px;
  font-weight: 500;
}

.about-sections .image-column .inner-column {
  position: relative;
  padding-left: 0px;
  padding-bottom: 0px;
}

.about-sections .image-column .inner-column .author-desc {
  background: orange;
  border-radius: 20px;
  bottom: 16px;
  left: 30px;
  padding: 5px 12px;
  position: absolute;
  z-index: 1;
}



.about-sections .image-column .inner-column .author-desc h2 {
  color: #fff;
  font-size: 21px;
  letter-spacing: 1px;
  margin: 0;
  text-align: center;
}

.about-sections .image-column .inner-column .author-desc span {
  font-size: 16px;
  letter-spacing: 3px;
  text-align: center;
  color: black;
  display: block;
  font-weight: 400;
}

.about-sections .image-column .image-1 {
  position: relative;
  /* bottom: -80px; */
}

.about-sections .image-column .image-2 {
  position: absolute;
  left: 0;
  bottom: 0;
}

.about-sections .image-column .image-2 img,
.about-sections .image-column .image-1 img {
  box-shadow: 0 30px 50px rgba(8, 13, 62, 0.15);
  border-radius: 20px;
  height: 300px;
  width: 300px;
}

.about-sections .image-column .video-link {
  position: absolute;
  left: 70px;
  top: 170px;
}

.about-sections .image-column .video-link .link {
  position: relative;
  display: block;
  font-size: 22px;
  color: #191e34;
  font-weight: 400;
  text-align: center;
  height: 100px;
  width: 100px;
  line-height: 100px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 30px 50px rgba(8, 13, 62, 0.15);
  transition: all 300ms ease;
}

@media screen and (max-width: 768px) {
  .about-sections .content-column {
    text-align: center;
  }
  .about-sections .content-column .inner-column {
    padding-left: 0;
   
  }
  /* .about-section .image-column {
    display: none;
  } */
  .about-sections .sec-title h3 {
    font-size: 22px;
  }

 

  .abouttitle{
    font-size:22px;
    width:320px;
  }
}

@media screen and (max-width: 576px) {
  .about-sections .btn-box a {
    padding: 10px 30px;
  }
  /* .about-section .author-desc {
    display: none;
  } */
}


/* Reset and global styles */
*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}


/* Footer styles */
.footer {
    background-color: #151515;
    padding: 70px 0;
    color: #FFF;
    text-align: center;
}

.footer_container{
    max-width: 80%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* margin-right: 20px; */
}

.footer-col {
    /* width: 25%; */
    padding: 0 15px;
    margin-bottom: 30px;
    text-align: left;
    /* margin-right: 1px; */
}

.footer-col h4 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
}

.footer-col ul li {
    margin-bottom: 10px;
}

.footer-col ul li a {
    color: #DDD;
    font-size: 1rem;
    font-weight: 300;
    text-decoration: none;
    transition: color 0.3s ease;
    display: flex;
    align-items: center;
}

.footer-col ul li a i {
    margin-right: 10px; /* Adjust this value for the desired space */
}

.footer-col ul li a:hover {
    color: #FFF;
    padding-left: 7px;
}

.social-links a {
    color: #FFF;
    background-color: rgba(255, 255, 255, 0.2);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 10px;
    margin-bottom: 10px;
    transition: all 0.5s ease;
}

.social-links a:hover {
    color: #151515;
    background-color: #FFF;
    align-items: center;
    justify-content: center;
}

.about-btn{
  background-color: #00aeef;
  margin-left: 160px;
}

.partners-section {
  padding: 50px 0;

  text-align: center;
}

.partners-section h2 {
  font-size: 36px;
  margin-bottom: 30px;
  font-weight: bold;
  color: #333;
}

.partner-item {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.partner-item:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.partner-item img {
  width: 100%;
  max-width: 200px;
  display: block;
  margin: 0 auto 15px;
  transition: transform 0.3s ease-in-out;
}

.partner-item:hover img {
  transform: scale(1.1);
}

.partner-item h4 {
  font-size: 24px;
  color: #555;
  margin-bottom: 10px;
}


/* Media Queries */
@media (max-width: 767px) {
    .footer-col {
        width: 50%;
    }
    .about-btn{
      margin-left: 10px;
    }
    .about-sections .text {
      width: 280px;
      margin-left: 30px;
    }
}

@media (max-width: 574px) {
    .footer-col {
        width: 100%;
    }
}

@media (max-width: 768px) {
  .site-heading h2 {
    font-size: 24px; /* Adjust font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .site-heading h2 {
    font-size: 20px; /* Adjust font size for even smaller screens */
  }
}

.whatsapp-float {
  position: fixed; 
  bottom: 20px; 
  right: 20px; 
  background-color: #25D366; 
  border-radius: 30px; 
  padding: 10px 20px; 
  display: flex; 
  align-items: center; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); 
  cursor: pointer; 
  z-index: 1000; 
  transition: background-color 0.3s ease; 
}

.whatsapp-float:hover {
  background-color: #128C7E; /* Darker green for hover effect */
}

.whatsapp-icon {
  color: #fff; /* White color for the icon */
  font-size: 24px; /* Size of the icon */
  margin-right: 10px; /* Space between the icon and the text */
}

.whatsapp-text {
  color: #fff; /* White color for the text */
  font-size: 14px; /* Size of the text */
  white-space: nowrap; /* Prevents text from wrapping */
}

/* General Styles */
/* General Styles */
.news-section {
  background-color: #f8f9fa;
  padding: 60px 0;
}

.news-section .site-heading h2 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #333;
}

.news-section .site-heading h4 {
  font-size: 20px;
  font-weight: 400;
  color: #777;
}

/* News Item Styles */
.news-item {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  max-width: 600px; /* Increase max-width for wider cards */
  margin: 0 auto 30px; /* Center align and add spacing between cards */
}

.news-item img {
  width: 100%;
  height: auto;
  display: block;
  border-bottom: 1px solid #ddd;
}

.news-item h5 {
  font-size: 18px;
  font-weight: 500;
  padding: 20px;
  color: #555;
}

.news-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .news-section .site-heading h2 {
    font-size: 28px;
  }

  .news-section .site-heading h4 {
    font-size: 16px;
  }

  .news-item h5 {
    font-size: 16px;
  }
}

